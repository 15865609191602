export { default as Apps } from './apps.svg'
export { default as Arrow } from './arrow.svg'
export { default as Building } from './building.svg'
export { default as Bulb } from './bulb.svg'
export { default as Case } from './case.svg'
export { default as Clock } from './clock.svg'
export { default as Computer } from './computer.svg'
export { default as Dollar } from './dollar.svg'
export { default as DoistLogo } from './dst-logo.svg'
export { default as FbIcon } from './fb-icon.svg'
export { default as Globe } from './globe.svg'
export { default as Heart } from './heart.svg'
export { default as InstagramIcon } from './inst-icon.svg'
export { default as LinkedInIcon } from './lkdin-icon.svg'
export { default as LogoTD } from './logo-td.svg'
export { default as MapIcon } from './map-pin-icon.svg'
export { default as PersonIcon } from './person-icon.svg'
export { default as CadetShape } from './shape-cadet.svg'
export { default as Stroller } from './stroller.svg'
export { default as TodoistLogo } from './td-logo.svg'
export { default as Ticket } from './ticket.svg'
export { default as TwIcon } from './twitter-icon.svg'
export { default as Umbrella } from './umbrella.svg'
